import {createRouter, createWebHistory} from 'vue-router';

// 路由配置
const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/ldxy/puzzle'),
    meta: {title: '乱斗西游答题器'},
  },
  {
    path: '/ldxy/puzzle',
    name: '乱斗西游答题器',
    component: () => import('@/views/ldxy/puzzle'),
    meta: {title: '乱斗西游答题器'},
  },
  {
    path: '/abo/carrotseries',
    name: '保卫萝卜盲盒',
    component: () => import('@/views/abo/carrotseries/index.vue'),
    meta: {title: '保卫萝卜盲盒'},
  },
  {
    path: '/mxd/timeline',
    name: '冒险岛版本历史',
    component: () => import('@/views/mxd/timeline/index.vue'),
    meta: {title: '冒险岛版本历史'},
  }
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局路由守卫（可选）
router.beforeEach((to, from, next) => {
  // 动态设置页面标题
  document.title = to.meta.title || '默认标题';
  next();
});

export default router;
